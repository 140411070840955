import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {StorageService} from "../storage/storage.service";
import {Entity} from "../entity.interface";
import {AppConfigService} from "../../config/app-config.service";
import {OnlineOfflineService} from "../onlineOffline/online-offline.service";

@Injectable()
export class DiscoveryDocumentCacheInterceptor implements HttpInterceptor {

  private oneWeek = 24 * 60 * 60 * 1000 * 7;

  constructor(
    private storageService: StorageService,
    private configurationService: AppConfigService,
    private onlineOfflineService: OnlineOfflineService
  ) {
  }

  private getCachedDocument(name : string) : string | null {
    const cachedDocument = localStorage.getItem(name);
    const cacheTimeStamp = localStorage.getItem(name + '_timestamp');
    if (cachedDocument && cacheTimeStamp) {
      const timeStamp = new Date(cacheTimeStamp).getTime();
      const now = new Date().getTime();
      const timeDiff = now - timeStamp;
      if (timeDiff < this.oneWeek) {
        return cachedDocument;
      } else {
        localStorage.setItem(name, '');
        localStorage.setItem(name + '_timestamp', '');
      }
    }
    return null;
  }

  private storeCachedDocument(name : string, document : string) {
    localStorage.setItem(name, document);
    localStorage.setItem(name + '_timestamp', new Date().toISOString());
  }

  intercept(request: HttpRequest<Entity<any>>, next: HttpHandler): Observable<HttpEvent<any>> {
    return new Observable(observer => {
      this.onlineOfflineService.isOnline().then(online => {
        const cachedDiscovery = this.getCachedDiscoveryResponse(request, online);
        if (cachedDiscovery) {
          observer.next(cachedDiscovery);
          observer.complete();
        }
        const cachedCerts = this.getCachedCertResponse(request, online);
        if (cachedCerts) {
          observer.next(cachedCerts);
          observer.complete();
        }

        return next.handle(request).pipe(
          tap((response) => {
            this.cacheDiscoveryDocument(request, response);
            this.cacheCerts(request, response);
          })).subscribe(observer);
      });
  });
  }

  private cacheCerts(request: HttpRequest<Entity<any>>, response: HttpSentEvent | HttpHeaderResponse | HttpResponse<any> | HttpProgressEvent | HttpUserEvent<any>) {
    if (this.isKeyClockCertsURL(request.url)) {
      if (response instanceof HttpResponse && response.status === 200) {
        console.debug('Caching certs');
        this.storeCachedDocument('keycloakCerts', JSON.stringify(response.body));
      }
    }
  }

  private cacheDiscoveryDocument(request: HttpRequest<Entity<any>>, response: HttpSentEvent | HttpHeaderResponse | HttpResponse<any> | HttpProgressEvent | HttpUserEvent<any>) {
    if (this.isKeycloakDiscoveryDocumentRequest(request.url)) {
      if (response instanceof HttpResponse && response.status === 200) {
        console.debug('Caching discovery document');
        this.storeCachedDocument('discoveryDoc', JSON.stringify(response.body));
      }
    }
  }

  private getCachedCertResponse(request: HttpRequest<Entity<any>>, online: boolean) {
    if (this.isKeyClockCertsURL(request.url)) {
      const cachedCerts: string | null = this.getCachedDocument('keycloakCerts');
      if (cachedCerts && !online) { // add online back when fixed it
        console.debug('Returning cached certs');
        return new HttpResponse({
          status: 200,
          body: JSON.parse(cachedCerts)
        });
      }
    }
    return null;
  }

  private getCachedDiscoveryResponse(request: HttpRequest<Entity<any>>, online: boolean) {
    if (this.isKeycloakDiscoveryDocumentRequest(request.url)) {
      const cachedDocument: string | null = this.getCachedDocument('discoveryDoc');
      if (cachedDocument && !online) { // add online back when fixed it
        console.debug('Returning cached discovery document');
        return new HttpResponse({
          status: 200,
          body: JSON.parse(cachedDocument)
        });
      }
    }
    return null;
  }

  private isKeyClockCertsURL(url: string): boolean {
    return url.endsWith('/protocol/openid-connect/certs');
  }

  private isKeycloakDiscoveryDocumentRequest(url: string): boolean {
    return url.endsWith('/.well-known/openid-configuration');
  }

}
