import {StorageService} from "../storage/storage.service";
import {Entity} from "../entity.interface";
import {HttpService} from "../http-service.interface";
import {QueryParams, QueryResult, Service} from "../service.interface";
import {AppConfigService} from "../../config/app-config.service";
import {OnlineOfflineService} from "../onlineOffline/online-offline.service";


export class CachedService<I, T extends Entity<I>> implements Service<I, T> {

  readonly type: string;
  online : boolean = false;


  constructor(protected storageService: StorageService,
              protected service: HttpService<I, T>,
              protected appConfigService : AppConfigService,
              protected onlineOfflineService : OnlineOfflineService,
              protected refreshIfOnline : boolean = false,
              protected ttl: number = 0) {
    this.type = service.type;
    this.registerToEvents();
    onlineOfflineService.isOnline().then(online => {
      this.online = online;
    });
  }

  private shouldRefresh() : boolean {
    console.log(`Refresh if online: ${this.refreshIfOnline} and online: ${this.online}`);
    return this.refreshIfOnline && this.online;
  }

  private registerToEvents() {
    this.onlineOfflineService.connectionChanged.subscribe(online => {
      this.online = online;
    });
  }

  async putItemInCache(item: T): Promise<void> {
    await this.storageService.setItem(this.service.getUrl(item.id), new CacheEntity(item, this.ttl));
    await this.updateQueryCaches(item);
  }

  public async updateQueryCaches<D extends Entity<I>>(item : D) : Promise<void> {
    // This is a no op for now
    return Promise.resolve();
  }

  async checkExpired(item : CacheEntity<I, T>) : Promise<T | null> {
    if (!item) {
      return null;
    }
    if (this.isValid(item)) {
      return item.data;
    } else {
      await this.storageService.removeItem(this.service.getUrl(item.data.id), this.type);
      return null;
    }
  }

  async putItemInCacheWithKey<D extends Entity<I>>(key: string, item: D): Promise<void> {
    await this.storageService.setItem(key, new CacheEntity(item, this.ttl));
    await this.updateQueryCaches(item);
  }

  async putListInCache<D extends Entity<I>>(url: string, items: D[]): Promise<void> {
    await this.storageService.setList(url, items, this.type);
  }

  async getFromCache(id: I): Promise<T | null> {
    return await this.checkExpired(await this.storageService.getItem(this.service.getUrl(id), this.type));
  }

  async getListFromCache<D extends Entity<I>>(url: string): Promise<D[]> {
    return await this.storageService.getList<D>(url, this.type);
  }

  async removeItemFromCache(id: I): Promise<void> {
    await this.storageService.removeItem(this.service.getUrl(id), this.type);
  }

  async getAllItemsFromCache() : Promise<T[]> {
    const items = await this.storageService.getAllItems<CacheEntity<I, T>>(this.type);
    const validItems = items.filter(item => this.checkExpired(item));
    return validItems.map(item => item.data);
  }

  async remove(id: I): Promise<void> {
    await Promise.all([
        this.storageService.removeItem(this.getUrl(id), this.type),
        this.delete(id)
      ]);
  }

  async find(id: I): Promise<T | null> {
    // Try to get the item from the storage
    let  item : T | null = await this.getFromCache(id);

    // If item is not found in the storage, get it from the service
    if (!item || this.shouldRefresh()) {
      item = await this.get(id);
      // store for future use
      if (item) {
        await this.putItemInCache(item);
      }
    }

    return item;
  }

  async findWithQuery<D extends Entity<I>>(query: QueryParams, converter?: (result : D[]) => T[]): Promise<D[]> {
    let items : D[] = await this.getListFromCache<D>(query.url);

    if ((!items || items.length === 0) || this.shouldRefresh()) {
      let itemsPromises : Promise<void>[] = [];
      try {
        let result : QueryResult<I, D> = await this.query<D>(query) || [];
        items = result.result;
        if (result.cacheable) {
          if (result.cacheElements) {
            if (converter) {
              itemsPromises = converter(items).map(item => this.putItemInCacheWithKey(this.getUrl(item.id), item));
            } else {
              itemsPromises = items.map(item => this.putItemInCacheWithKey(this.getUrl(item.id), item));
            }
          }
          await Promise.all([this.putListInCache(query.url, items), ...itemsPromises]);
        }
      } catch (e) {
        console.error(`Error getting items from service ${e}`);
      }
    }

    return items
  }

  async findAll(): Promise<T[]> {
    let items : T[] = await this.getAllItemsFromCache();
    if ((!items || items.length === 0) || this.shouldRefresh()) {
      items = await this.getAll() || [];
      const itemsPromises = items.map(item => this.putItemInCacheWithKey(this.getUrl(item.id), item));
      await Promise.all(itemsPromises);
    }

    return items;
  }

  async create(item: T): Promise<T> {
    let serviceItem : T = await this.post(item);
    if (serviceItem) {
      await this.putItemInCacheWithKey(this.getUrl(item.id), item);
    }

    return serviceItem;
  }

  async update(item: T): Promise<T> {
    let serviceItem : T = await this.put(item);
    if (serviceItem) {
      await this.putItemInCacheWithKey(this.getUrl(item.id), item);
    }

    return serviceItem;
  }

  // create delegate methods for the http service interface

  getUrl(id?: I): string {
    return this.service.getUrl(id);
  }

  getAll(): Promise<T[]> {
    return this.service.getAll();
  }

  get(id: I): Promise<T> {
    return this.service.get(id);
  }

  post(item: T): Promise<T> {
    return this.service.post(item);
  }

  put(item: T): Promise<T> {
    return this.service.put(item);
  }

  delete(id: I): Promise<void> {
    return this.service.delete(id);
  }

  query<D extends Entity<I>>(query: QueryParams): Promise<QueryResult<I, D>> {
    return this.service.query(query);
  }

  private isValid(item : CacheEntity<I, T>) : boolean {
    return item.validUntil == 0 || item.validUntil > new Date().getTime();
  }

}

export class CacheEntity<I, T extends Entity<I>> implements Entity<I> {
  type : string;
  data : T;
  validUntil : number;

  constructor(entity : T, ttl : number) {
    this.type = entity.type;
    this.data = entity;
    this.validUntil = ttl == 0 ? 0 : (new Date().getTime() + ttl * 1000);
  }

}
