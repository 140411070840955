import {APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom} from '@angular/core';
import {bootstrapApplication} from '@angular/platform-browser';
import {provideRouter, Router, RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {routes} from './app/app.routes';
import {AppComponent, setServicesFactory, storageFactory} from './app/app.component';
import {environment} from './environments/environment';
import {OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppConfigService} from "./app/config/app-config.service";
import {AuthService} from "./app/api/auth/auth.service";
import {HttpErrorInterceptor} from "./app/api/error/http-error.interceptor";
import {DirtyDataInterceptor} from "./app/api/dirtyData/dirty-data.interceptor";
import {DiscoveryDocumentCacheInterceptor} from "./app/api/auth/discovery-document-cache.interceptor";
import {defineCustomElements} from '@ionic/pwa-elements/loader';

import * as Sentry from "@sentry/angular";
import {SubscriptionService} from "./app/api/subscription/subscription.service";

Sentry.init({
  dsn: "https://2b1156c89dfec19a7200637f15b7d061@o4506842072481792.ingest.us.sentry.io/4506842076479488",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "staging.hec-local.co.uk"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    importProvidersFrom(
      IonicModule.forRoot({}),
      OAuthModule.forRoot({
        resourceServer: {
          allowedUrls: ['https://staging.hec-local.co.uk/hec/api',
            'https://dev.hec-local.co.uk:8443/hec/api'],
          sendAccessToken: true
        },

      }),
      HttpClientModule,
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: setServicesFactory,
      deps: [AppConfigService, AuthService, SubscriptionService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: DirtyDataInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: DiscoveryDocumentCacheInterceptor, multi: true},
    {provide: OAuthStorage, useFactory: storageFactory},
    provideRouter(routes),
  ],
}).then();

defineCustomElements(window).then();
