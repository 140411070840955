import { Injectable } from '@angular/core';
import {Platform} from "@ionic/angular";
import {
  CustomerInfo,
  LOG_LEVEL,
  Purchases, PurchasesOfferings, PurchasesStoreProduct
} from "@revenuecat/purchases-capacitor";
import {Capacitor} from "@capacitor/core";
import {ErrorService} from "../error/error.service";
import {BehaviorSubject, from} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {PurchasesPackage} from "@revenuecat/purchases-typescript-internal-esm/dist/offerings";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private customerInfo? : CustomerInfo;
  public isSubscribed: boolean = false;

  private refreshSubject = new BehaviorSubject<boolean>(true);
  private refresh$ = this.refreshSubject.asObservable();

  offerings$ = this.refresh$
    .pipe(
      switchMap(() =>
        from(this.getOfferings()
          .catch(error => {
            this.errorService.registerError(error);
            return { current: {
              availablePackages: [] as PurchasesPackage[]
              }};
          }))
          .pipe(
            map(offerings =>
              offerings.current?.availablePackages)
          )
        )
    );


  constructor(private platform : Platform,
              private errorService : ErrorService) {
  }

  public async init() {
    console.log('Platform is ' + Capacitor.getPlatform());
    if (!this.isCurrentPlatformSupported()) {
      this.isSubscribed = true;
      return;
    }

    await this.platform.ready();
    try {
      await Purchases.setLogLevel({level: LOG_LEVEL.DEBUG});

      if (Capacitor.getPlatform() === 'ios') {
        console.log('Initializing Purchases for iOS');
        await Purchases.configure({apiKey: "appl_GJvVtzPCshLxjUlFJdCIRuZCMRI"});
        console.log('Done');
      }

      this.refreshSubject.next(true);
      await Purchases.addCustomerInfoUpdateListener((customerInfo) => {
        console.log('Customer Info: ' + JSON.stringify(customerInfo));
        this.customerInfo = customerInfo;
        this.isSubscribed = !!this.customerInfo.entitlements.active['basic'];
      });
    } catch (error) {
      this.errorService.registerError(error);
    }
  }

  public async getOfferings() : Promise<PurchasesOfferings> {
    if (this.isCurrentPlatformSupported()) {
      return Purchases.getOfferings();
    }
    return  {
      all: {},
      current: null
    };
  }

  private supportedPlatforms = ['ios'];

  private isCurrentPlatformSupported() : boolean {
    return this.supportedPlatforms.includes(Capacitor.getPlatform());
  }


  public async userLoggedIn(username : string) {
    try {
      console.log("Logging user in: " + username);
      if (this.isCurrentPlatformSupported()) {
        const loginResult = await Purchases.logIn({appUserID: username});
        console.log("Login Result: " + JSON.stringify(loginResult));
        this.customerInfo = loginResult.customerInfo;
      }
    } catch (error) {
      this.errorService.registerError(error);
    }
  }

  public async buy(offering : PurchasesStoreProduct) {
    console.log('Buying offering: ' + JSON.stringify(offering));
    const purchaseResult = await Purchases.purchaseStoreProduct({
      product: offering
    });
    if (typeof purchaseResult.customerInfo.entitlements.active['basic'] !== 'undefined') {
      this.isSubscribed = true;
    }
  }
}
