import {AssociationKey} from "./association-key.model";
import {AssociationType} from "./association-type";
import {Entity} from "../api/entity.interface";
import {LocalFile} from "./local-file.model";

export class AssociatedPhoto<I> implements Entity<AssociationKey<I>> {
  id : AssociationKey<I>;
  type: string = 'AssociatedPhoto';
  photos: LocalFile[];

  constructor(id: AssociationKey<I>, photos: LocalFile[]) {
    this.id = id;
    this.photos = photos;
  }


}
