import {AssociationType} from "./association-type";

export class AssociationKey<I> {
  associationType: AssociationType;
  associationId: I;

  // factory method to create an key association for an event
  static createEventKey(eventId: string) {
    return new AssociationKey<string>(AssociationType.EVENT, eventId);
  }

  static createAssociationKey<I>(associationType: AssociationType, associationId: I) {
    return new AssociationKey<I>(associationType, associationId);
  }

  constructor(associationType: AssociationType, associationId: I) {
    this.associationType = associationType;
    this.associationId = associationId;
  }
}
