import {Injectable, Injector} from '@angular/core';
import {AppConfigService} from "../config/app-config.service";
import {OnlineOfflineService} from "./onlineOffline/online-offline.service";

import {StorageService} from "./storage/storage.service";
import {HttpService} from "./http-service.interface";
import {Entity} from "./entity.interface";
import {CachedService} from "./cache/cached-service.service";
import {QueryParams, QueryResult, Service} from "./service.interface";

@Injectable({
  providedIn: 'root'
})
export abstract class OfflineAbstractService<I, T extends Entity<I>> implements Service<I, T>, HttpService<I, T> {

  private config: AppConfigService;
  private online : boolean = false;
  protected cachingService : CachedService<I, T>;

  protected constructor(inject : Injector,
                        shouldRefreshIfOnline : boolean = false) {
    this.config = inject.get(AppConfigService);
    this.cachingService = new CachedService<I, T>(
      inject.get(StorageService),
      this,
      this.config,
      inject.get(OnlineOfflineService),
      shouldRefreshIfOnline);
  this.registerToEvents(inject.get(OnlineOfflineService));
  }

  private registerToEvents(onlineOfflineService: OnlineOfflineService) {
    onlineOfflineService.connectionChanged.subscribe(online => {
      this.online = online;
    });
  }

  // delegate methods to the Service interface
  create(item: T): Promise<T> {
    const message = this.validate(item);
    if (message.length == 0) {
      return this.cachingService.create(item);
    }
    return Promise.reject(message);
  }

  update(item: T): Promise<T> {
    const message = this.validate(item);
    if (message.length == 0) {
      return this.cachingService.update(item);
    }
    return Promise.reject(message);
  }
  remove(id: I): Promise<void> {
    return this.cachingService.remove(id);
  }

  find(id: I): Promise<T | null> {
    return this.cachingService.find(id);
  }

  async findAll(): Promise<T[]> {
    const items = await this.cachingService.findAll();
    return this.sort(items);
  }

  async findWithQuery<D extends Entity<I>>(query: QueryParams, converter?: (result : D[]) => T[]): Promise<D[]> {
    return await this.cachingService.findWithQuery<D>(query, converter);
  }

  get appConfigService() : AppConfigService {
    return this.config;
  }

  get isOnline() : boolean {
    return this.online;
  }

  abstract get type() : string;
  abstract getUrl(id?: I): string;
  abstract delete(id: I): Promise<void>;
  abstract get(id: I): Promise<T>;
  abstract getAll(): Promise<T[]>;
  abstract post(item: T): Promise<T>;
  abstract put(item: T): Promise<T>;
  abstract query<D extends Entity<I>>(query : QueryParams) : Promise<QueryResult<I, D>>;
  abstract sort(items: T[]): T[];
  abstract validate(item: T): string;

}
