import {Injectable} from "@angular/core";
import {Drivers, Storage} from '@ionic/storage';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import {StorageWrapper} from "./storage-wrapper.interface";
import {Entity} from "../entity.interface";
import {AuthService} from "../auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class StorageFactoryService {

  storageMap : Map<string, StorageWrapper<any>> = new Map<string, StorageWrapper<any>>();

  constructor(private authService: AuthService) {
  }

  private getUserType(type : string) : string {
    return this.authService.getUsername() + "_" + type;
    // let user = this.authService.getUserProfile().;
    // return user ? user.preferred_username : "anonymous";
  }

  public async getStorage<T extends Entity<any>>(type : string): Promise<StorageWrapper<T>> {
    let userType = this.getUserType(type);
    let storage  = this.storageMap.get(userType);
    if (!storage) {
      let platformStorage = await this.createStorage(userType);
      storage = new StorageWrapper<T>(platformStorage, userType);
      this.storageMap.set(userType, storage);
    }
    return storage;

  }

  private async createStorage(type : string): Promise<Storage> {
    let storage = new Storage({
      name: type,
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage],
    });
    await storage.defineDriver(CordovaSQLiteDriver);
    return await storage.create();
  }

}
