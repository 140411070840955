import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  private errorSubject = new Subject<string | null>();
  error$ = this.errorSubject.asObservable();

  constructor() { }

  public registerError(errorMessage : any) : void {
    let errorString : string;
    // check if this is an Error or a string...
    if (errorMessage instanceof Error) {
      errorString = errorMessage.message;
    } else {
      errorString = errorMessage;
    }
    console.log(`Error recived in error service ${errorString}`);
    this.errorSubject.next(errorString);
  }

  public clearError() : void {
    this.errorSubject.next(null);
  }
}
