import {Injectable} from '@angular/core';
import {Drivers, Storage} from '@ionic/storage';
import {Entity} from "../entity.interface";
import {StorageFactoryService} from "./storage-factory.service";


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  static SIMPLE = 'simple_type';
  constructor(private storageFactory: StorageFactoryService) {
  }

  /**
   * This method is used to record a value associated with a key into the storage.
   *
   * @param key The unique key which this value should be associated with in the storage.
   * @param value The data object of Type to store in the storage
   *
   * @returns Promise<void> A Promise that resolves once the data is stored and id is recorded.
   *
   * @throws Error if the storage actions fail, either storing the data or recording the id.
   */
  async setItem<T extends Entity<any>>(key: string, value: T): Promise<void> {
    console.log("Storing type: " + value.type);
    return await this.storageFactory.getStorage(value.type).then(async storage => {
      await storage.set(key, value)
    });
  }

  /**
   * Asynchronously removes an item and its corresponding key from the storage.
   *
   * @param key The key associated with the item to be removed.
   * @param type The type of the item to be removed.
   *
   * @returns Promise<void> Resolves once the item and its id have been removed from the storage.
   */
  async removeItem(key: string, type : string): Promise<void> {
    return await this.storageFactory.getStorage(type).then(async storage => storage.remove(key));
  }

  async clear(type :string) : Promise<void> {
    return await this.storageFactory.getStorage(type).then(async storage => storage.clear());
  }

  /**
   * Asynchronously removes an item and its corresponding key from the storage.
   *
   * @param key The key associated with the item to be removed.
   * @param type The type of the item to be removed.
   *
   * @returns Promise<void> Resolves once the item and its id have been removed from the storage.
   */
  async getItem<T extends Entity<any>>(key: string, type : string): Promise<T> {
    const storage = await this.storageFactory.getStorage<T>(type);
    const result = await storage.get(key);

    return (result as T);
  }

  /**
   * Retrieves a list of items from the storage or an empty list if it doesn't exist
   * @param key
   * @param type
   */
  async getList<D  extends Entity<any>>(key: string, type : string): Promise<D[]> {
    const storage = await this.storageFactory.getStorage<D>(type);
    return await storage.getList(key);
  }

  async setList<T extends Entity<any>>(key: string, value: T[], type : string): Promise<void> {
    return await this.storageFactory.getStorage(type).then(async storage => storage.setList(key, value));
  }

  async addToList<T extends Entity<any>>(key: string, value: T) : Promise<void> {
    return this.storageFactory.getStorage(value.type).then(async storage => {
      const list = await storage.getList(key);
      // replace the item if it already exists
      const index = list.findIndex(item => item.id === value.id);
      if (index > -1) {
        list[index] = value;
      } else {
        list.push(value);
      }
      await storage.setList(key, list);
    });
  }

  async removeFromList<T extends Entity<any>>(key: string, value: T) : Promise<void>{
    return this.storageFactory.getStorage(value.type).then(async storage => {
      const list = await storage.getList(key);
      const index = list.findIndex(item => item.id === value.id);
      console.log('Removing item from list: ' + value.id + ' at index: ' + index);
      if (index > -1) {
        list.splice(index, 1);
        await storage.setList(key, list);
      }
    });
  }

  /**
   * Retrieves all IDs for a given storage type.
   *
   * @param {string} type - The type of storage to retrieve IDs from.
   * @return {Promise<string[]>} A promise that resolves to an array of IDs.
   */
  async getAllIds(type : string) : Promise<string[]> {
    return await this.storageFactory.getStorage(type).then(async storage => storage.keys());
  }

  /**
   * Retrieves all items for a given storage type.
   *
   * @param {string} type - The type of storage to retrieve items from.
   * @return {Promise<T[]>} A promise that resolves to an array of items.
   */
  async getAllItems<T extends Entity<any>>(type : string): Promise<T[]> {
    const ids = await this.getAllIds(type) || [];
    return this.getAllItemsUsingIds<T>(ids, type);
  }

  async forEach<T extends Entity<any>>(type: string, callback: (value: T, key: string, iterationNumber: Number) => any): Promise<void> {
    return this.storageFactory.getStorage<T>(type).then(s => s.forEach(callback));
  }

  async forEachList<T extends Entity<any>>(type: string, callback: (value: T[], key: string, iterationNumber: Number) => any): Promise<void> {
    return this.storageFactory.getStorage<T>(type).then(s => s.forEachList(callback));
  }

  private async getAllItemsUsingIds<T extends Entity<any>>(ids: string[], type : string): Promise<T[]> {
    const itemsPromises : Promise<T>[] = ids.map((id) => this.getItem<T>(id, type));
    const items = await Promise.all(itemsPromises);
    return items.filter(item => item !== null);
  }

}
