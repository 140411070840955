import { Injectable } from '@angular/core';
import {Subject } from 'rxjs';
import {Network} from '@capacitor/network';

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class OnlineOfflineService {

  private internalConnectionChanged = new Subject<boolean>();

  constructor() {
    Network.addListener('networkStatusChange',
        status => {
          console.log('Network status changed', status.connected);
          this.updateOnlineStatus(status.connected)
        });
    this.isOnline().then(online => {
      this.updateOnlineStatus(online);
    });
 }

  get connectionChanged() {
    return this.internalConnectionChanged.asObservable();
  }

  async isOnline() {
    let connectionStatus = await Network.getStatus();
    return connectionStatus.connected;
  }

  private updateOnlineStatus(status : boolean) {
    this.internalConnectionChanged.next(status);
  }

  public  async runIfOnline<T>(func: () => Promise<T>): Promise<T | null> {
    if (await this.isOnline()) {
      return func();
    } else {
      console.log('Not online, cannot run function');
      return null;
    }
  }

}
