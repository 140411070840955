import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {Component, EnvironmentInjector, inject, OnDestroy, OnInit} from '@angular/core';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {AppConfigService} from "./config/app-config.service";
import {HeaderComponent} from "./header/header.component";
import {AuthService} from "./api/auth/auth.service";
import {ErrorComponent} from "./error/error.component";
import {DirtyDataWriterService} from "./api/dirtyData/dirty-data-writer.service";
import {OAuthStorage} from "angular-oauth2-oidc";
import {Browser} from "@capacitor/browser";
import {SubscriptionService} from "./api/subscription/subscription.service";

export function setServicesFactory(appConfigService: AppConfigService,
                                   authService : AuthService,
                                   subscriptionService : SubscriptionService) {
  return () => appConfigService.loadConfig().then(() =>
               subscriptionService.init().then(() =>
               authService.init().then()));
}

export function storageFactory() : OAuthStorage {
  return localStorage
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
    imports: [ FormsModule,
        IonicModule,
        RouterLink,
        RouterLinkActive,
        CommonModule,
        HeaderComponent,
        ErrorComponent
    ],
  providers: [
    // can I create the services here?

  ]
})
export class AppComponent implements OnInit, OnDestroy {
  public environmentInjector = inject(EnvironmentInjector);
  constructor(private dirtyDataWriterService : DirtyDataWriterService,
              private authService : AuthService) {}
  ngOnInit() {
    this.dirtyDataWriterService.startPeriodicTask();
  }

  ngOnDestroy() {
    this.dirtyDataWriterService.stopPeriodicTask();
  }

  accountSettings() {
    this.authService.openAccountSettings();
  }

  openExternalLink(url: string) {
    Browser.open({ url: url });
  }

}
