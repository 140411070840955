import {Injectable, Injector} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AssociationType} from "../../models/association-type";
import {Directory, Filesystem} from "@capacitor/filesystem";
import {LocalFile} from "../../models/local-file.model";
import {Photo} from "@capacitor/camera";
import {Platform} from "@ionic/angular";
import {OfflineAbstractService} from "../offline-abstract.service";
import {AssociationKey} from "../../models/association-key.model";
import {AssociatedPhoto} from "../../models/associated-photo.model";
import {Entity} from '../entity.interface';
import {QueryParams, QueryResult} from '../service.interface';
import {firstValueFrom} from "rxjs";
import {catchError, map} from "rxjs/operators";

const IMAGE_DIR = 'images';


@Injectable({
  providedIn: 'root'
})
export class PhotoService extends OfflineAbstractService<AssociationKey<any>, AssociatedPhoto<any>> {

  constructor(private http : HttpClient,
              private platform: Platform,
              inject : Injector) {
    super(inject);
    // ensure the association directories exist
    this.checkAndCreateDirectories([IMAGE_DIR, `${IMAGE_DIR}/${AssociationType.EVENT}`]).then();
  }

  public async getPhotosForEvent(eventId: string) {
    return await this.find(AssociationKey.createEventKey(eventId));
  }

  public async getAssociatedPhoto(associationId: string, associationType: AssociationType) {
    return await this.find(AssociationKey.createAssociationKey(associationType, associationId));
  }

  public async savePicture(cameraPhoto: Photo, type : AssociationType, id : string) {
    const base64Data = cameraPhoto.base64String;
    if (base64Data) {
      const fileName = this.createFileName();
      return await this.saveFile(base64Data, fileName, type, id);
    }
    console.error('No base64 data in photo');
    return null;
  }

  public async deletePhoto(file: LocalFile) {
    return await Filesystem.deleteFile({
      directory: Directory.Data,
      path: file.path
    });
  }

  override get type(): string {
    return "AssociatedPhoto";
  }
  override getUrl(id?: AssociationKey<any> | undefined): string {
    if (id) {
      return `${this.appConfigService.getPhotoUrl()}/${id.associationType}/${id.associationId}`;
    }
    throw new Error("Unable to get URL for photos without an id");
  }
  override delete(id: AssociationKey<any>): Promise<void> {
    throw new Error('Method not implemented.');
  }
  override get(id: AssociationKey<any>): Promise<AssociatedPhoto<any>> {
    // this should just return the metadata without the image data - this will be added in find...
    return firstValueFrom(this.http.get<AssociatedPhoto<any>>(this.getUrl(id)).pipe(
      map((data) => {
        if (data) {
          data.type = 'AssociatedPhoto';
        }
        return data;
      }),
      catchError(() => {
        console.debug('Returning mock data as we had an error communicating with ' +
          'the service getting photos for the association')
        return Promise.resolve(new AssociatedPhoto(id, []));
      })
    ));
  }

  override async find(id : AssociationKey<any>) : Promise<AssociatedPhoto<any> | null> {
    let associatedPhotos : AssociatedPhoto<any> | null = await this.cachingService.find(id);
    if (associatedPhotos) {
      associatedPhotos = await this.populatePhotos(associatedPhotos);
    }
    return associatedPhotos;
  }

  private async populatePhotos(associatedPhotos: AssociatedPhoto<any>) {
    for (let photo of associatedPhotos.photos) {
      if (!photo.data) {
        let base64 : string = await this.readFromFileOrServer(photo, associatedPhotos);
        if (base64) {
          photo.data = base64;
        } else {
          console.error('No data found from file or server');
        }
      }
    }
    return associatedPhotos;
  }

  private async readFromFileOrServer(photo: LocalFile, associatedPhotos: AssociatedPhoto<any>) {
  try {
    const result = await Filesystem.readFile({
      directory: Directory.Data,
      path: photo.path
    });
    return result.data as string;
  } catch (err : any) {
    if (err.message.includes('does not exist') || err.message.includes('there is no such file')) {
      console.info('Reading from server');
      const base64 = await this.readPhotoBytesFromServer(photo, associatedPhotos);
      if (base64) {
        await this.saveFile(base64, photo.name, associatedPhotos.id.associationType, associatedPhotos.id.associationId);
        return base64;
      }
    }
    console.error('Ignoring error reading file or server data', err);
    return "";
  }
}

  private async readPhotoBytesFromServer(photo: LocalFile, associatedPhotos: AssociatedPhoto<any>) {
    return firstValueFrom(this.http.get(`${this.appConfigService.getPhotoUrl()}/${associatedPhotos.id.associationType}/${associatedPhotos.id.associationId}/${photo.name}`, {responseType: 'blob'}).pipe(
      map(async (blob) => {
        return await PhotoService.blobToBase64(blob);
      })
    ));
  }

  override getAll(): Promise<AssociatedPhoto<any>[]> {
    throw new Error('Method not implemented.');
  }
  override async post(item: AssociatedPhoto<any>): Promise<AssociatedPhoto<any>> {
    // for all photos in the item
    // create a form data object and append the binary data to it in a key named photo
    // send the form data to the server
    // return the associated photo without the data
    const formData = await PhotoService.toFromData(item);
    await firstValueFrom(
      this.http.post(this.getUrl(item.id), formData).pipe(
        catchError((error) => {
          console.error('Error posting photo data', error);
          return Promise.resolve();
        })
      ));
    // return the associated photo without the photo binary data so this is cached without the photo data
    item.photos = this.removeBinaryData(item.photos);
    return item;
  }

  private removeBinaryData(photos: LocalFile[]) {
    return photos.map(p => {
      return {
        name: p.name,
        path: p.path,
        data: ""
      };
    });
  }

  override async put(item: AssociatedPhoto<any>): Promise<AssociatedPhoto<any>> {
    const formData = await PhotoService.toFromData(item);
    await firstValueFrom(this.http.put(this.getUrl(item.id), formData));

    // return the associated photo without the photo binary data so this is cached without the photo data
    item.photos = this.removeBinaryData(item.photos);
    return item;
  }

  public static async toFromData(item: AssociatedPhoto<any>) {
    const formData = new FormData();
    formData.append("id", item.id.associationId)
    formData.append("type", item.id.associationType as string)
    let metaData = [];
    for (let photo of item.photos) {
      // convert base 64 encoded image to a blob
      const blob = await this.base64ToBlob(photo.data as string);
      formData.append('images', blob, photo.name);
      metaData.push({
        name: photo.name,
        path: photo.path
      });
    }
    formData.append('meta', JSON.stringify(metaData));
    return formData;
  }

  public static async fromFormData(formData : FormData): Promise<AssociatedPhoto<any>>  {
    let associationId : string = formData.get("id") as string;
    let associationType : AssociationType = formData.get("type") as AssociationType;
    let associationKey = AssociationKey.createAssociationKey(associationType, associationId);
    let images : File[] = formData.getAll('images') as File[];
    let imageMap = images.reduce((map, image) => {
      map[image.name] = image;
      return map;
    }, {} as { [key: string]: File });
    let localFiles : LocalFile[] = JSON.parse(formData.get("meta") as string);
    let photos = localFiles.map(async localFile => {
      return {
        name: localFile.name,
        path: localFile.path,
        data: await PhotoService.blobToBase64(imageMap[localFile.name])
      }
    });
    let resolvedPhotos : LocalFile[] = await Promise.all(photos);
    return new AssociatedPhoto<any>(associationKey, resolvedPhotos);
  }

  override async query<D extends Entity<AssociationKey<any>>>(query: QueryParams): Promise<QueryResult<AssociationKey<any>, D>> {
    // if (query.type === "PhotoData") {
    //   return await this.getPhotoMetaData(query.url, query.params["associationType"], query.params["associationId"]) as unknown as Promise<QueryResult<AssociationKey<any>, D>>;
    // }
    return Promise.reject("Unknown query type");
  }

  override sort(items: AssociatedPhoto<any>[]): AssociatedPhoto<any>[] {
    throw new Error('Method not implemented.');
  }
  override validate(item: AssociatedPhoto<any>): string {
    return "";
  }

  private static async base64ToBlob(base64: string): Promise<Blob> {
    const response = await fetch(this.ensureBase64URI(base64));
    return await response.blob().then(async blob => {
      return blob;
    });
  }

  public static  ensureBase64URI(data: string) {
    if (data) {
      if (data.startsWith("data:image/jpeg;base64,")) {
        return data;
      } else {
        return "data:image/jpeg;base64," + data;
      }
    }
    return "";
  }

  private async checkAndCreateDirectories(directory: string[]) {
    for (let d of directory) {
      await this.checkAndCreateDirectory(d);
    }
  }

  private async checkAndCreateDirectory(directory: string) {
    // check if the directory exists and create if not
    await Filesystem.readdir({
      directory: Directory.Data,
      path: directory
    }).then(() => {
    }, async () => {
      await Filesystem.mkdir({
        directory: Directory.Data,
        path: directory
      }).then(() => {
      });
    });
  }

  private createFileName() {
    const date = new Date();
    const time = date.getTime();
    return `${time}.jpeg`;
  }

  private async saveFile(base64Data: string, fileName: string, type : AssociationType, id : string): Promise<LocalFile> {
    await this.checkAndCreateDirectory(this.getDirectoryName(type, id));
    const path = `${this.getDirectoryName(type, id)}/${fileName}`;
    await Filesystem.writeFile({
      directory: Directory.Data,
      path: path,
      data: base64Data
    });
    return {
      name: fileName,
      path: path,
      data: base64Data
    };
  }

  // private async readAsBase64(cameraPhoto: Photo) {
  //   if (this.platform.is('hybrid')) {
  //
  //     const file = await Filesystem.readFile({
  //       path: cameraPhoto.path!
  //     });
  //     return file.data as string;
  //
  //   } else {
  //
  //     const response = await fetch(cameraPhoto.webPath!);
  //     const blob = await response.blob();
  //     return await this.blobToBase64(blob) as string;
  //
  //   }
  // }

  private static async blobToBase64(blob: Blob) : Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(blob);
    });
  }

  private getDirectoryName(type: AssociationType, id: string) {
    return `${IMAGE_DIR}/${type}/${id}`;
  }
}
