import {Storage} from '@ionic/storage';
import {Entity} from "../entity.interface";

export class StorageWrapper<T extends Entity<any>>  {
  private storage: Storage;
  private readonly type: string;

  constructor(storage: Storage, type: string) {
    this.storage = storage;
    this.type = type;
  }

  getType() : string {
    return this.type;
  }

  get(key: string): Promise<T> {
    return (this.storage.get(key) as Promise<T>);
  }

  async getList(key: string): Promise<T[]> {
    const val = await this.storage.get(key);
    if (val === null || val === undefined) {
      return [];
    }
    return val;
  }

  setList(key: string, value: T[]): Promise<any> {
    return this.storage.set(key, value);
  }

  set(key: string, value: T): Promise<any> {
    return this.storage.set(key, value);
  }

  remove(key: string): Promise<any> {
    return this.storage.remove(key);
  }

  clear(): Promise<any> {
    return this.storage.clear();
  }

  length(): Promise<number> {
    return this.storage.length();
  }

  keys(): Promise<string[]> {
    return this.storage.keys();
  }

  forEach(callback: (value: T, key: string, iterationNumber: Number) => any): Promise<void> {
    return this.storage.forEach(callback);
  }

  forEachList(callback: (value: T[], key: string, iterationNumber: Number) => any): Promise<void> {
    return this.storage.forEach(callback);
  }

}
