import {Injectable, OnDestroy} from '@angular/core';
import {OnlineOfflineService} from "../onlineOffline/online-offline.service";
import {StorageService} from "../storage/storage.service";
import {AuthService} from "../auth/auth.service";
import {FailedRequestEntity} from "./dirty-data.interceptor";
import {ErrorService} from "../error/error.service";


@Injectable({
  providedIn: 'root'
})
export class DirtyDataWriterService implements OnDestroy {
  private intervalId: any;
  private online : boolean = false;
  private processInFlight: boolean = false;

  private worker: Worker;



  constructor(private onlineOfflineService : OnlineOfflineService,
              private storageService: StorageService,
              private authenticationServer : AuthService,
              private errorService : ErrorService) {

    this.worker = new Worker('/assets/dirty-data-writer.js');
    this.worker.onmessage = (event) => {
      this.handleCallBack(event.data).then(() => {
        console.info('Callback processing finished');
      });
    };

    this.registerToEvents(onlineOfflineService);
    onlineOfflineService.isOnline().then(online => {
      this.online = online;
    });

  }

  ngOnDestroy() {
    this.stopPeriodicTask();
    if (this.worker) {
      this.worker.terminate();
    }
  }

  public async findFailedRequest(failedRequest : FailedRequestEntity): Promise<FailedRequestEntity | null> {
    return await this.storageService.getItem<FailedRequestEntity>(failedRequest.id, failedRequest.type);
  }

  public async storeFailedRequest(failedRequest : FailedRequestEntity): Promise<void> {
    await this.storageService.setItem(failedRequest.id, failedRequest);
  }

  private async removeFailedRequest(failedRequest : FailedRequestEntity) {
    await this.storageService.removeItem(failedRequest.id, failedRequest.type);
  }

  private registerToEvents(onlineOfflineService: OnlineOfflineService) {
    onlineOfflineService.connectionChanged.subscribe(online => {
      this.online = online;
      if (online) {
        this.startPeriodicTask();
      } else {
        this.stopPeriodicTask();
      }
    });
  }

  private async handleCallBack(data: any) {
    let failedRequest = data?.data ? await this.findFailedRequest(new FailedRequestEntity(data.data.url, data.data.method, data.data.body)) : null;
    if (failedRequest) {
      if (data.status === 200) {
        await this.removeFailedRequest(failedRequest)
      } else if (data.status >= 200 && data.status < 500) {
        // The request was successful, but the server returned an application error, track the failure and remove from storage
        // if over the limit
        failedRequest.retries = failedRequest.retries + 1;
        if (failedRequest.retries > 50) {
          await this.removeFailedRequest(failedRequest);
          if (failedRequest.body) {
            if (failedRequest.body instanceof FormData) {
              this.errorService.registerError(`Unable to upload photo`);
            } else {
              this.errorService.registerError(`Unable to upload ${failedRequest.body.type}`);
            }
          }
        } else {
          await this.storeFailedRequest(failedRequest);
        }
      } else {
        console.info(data.statusText);
      }
    }

    if (data.complete) {
      console.info('Worker has completed all tasks');
      this.processInFlight = false;
    }
  }

  private postDirtyDataToWorker(): void {
    if (this.authenticationServer.isLoggedIn() && this.online  && !this.processInFlight) {
      // always drain post requests
      this.drainDirtyDataQueue().then((postItemsQueued : boolean) => {
        if (postItemsQueued) {
          console.info('Processing dirty data')
        }
      });
    }
  }


  private async drainDirtyDataQueue() : Promise<boolean> {
    let failedRequests : FailedRequestEntity[] = await this.storageService.getAllItems(`FailedRequestEntity`);
    // sort list by time from oldest to newest
    failedRequests.sort((a, b) => a.time - b.time);
    if (failedRequests.length != 0) {
      try {
        this.processInFlight = true;
        this.worker.postMessage({
          items: failedRequests,
          authToken: this.authenticationServer.getAuthToken()
        });
        return true;
      } catch (error) {
        console.error('Error posting dirty data to worker', error);
      }
    }
    return false;
  }

  startPeriodicTask(): void {
    // Adjust the interval (in milliseconds) based on your requirements
    this.intervalId = setInterval(() => {
      this.postDirtyDataToWorker();
    }, 5000); // Perform the task every 5 seconds
  }

  stopPeriodicTask(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

}
