import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {StorageService} from "../storage/storage.service";
import {Entity} from "../entity.interface";
import {DirtyDataWriterService} from "./dirty-data-writer.service";
import {PhotoService} from "../photo/photo.service";

@Injectable()
export class DirtyDataInterceptor implements HttpInterceptor {

  constructor(
    private storageService: StorageService,
    private dirtyDataWriterService: DirtyDataWriterService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
          console.log('Dirty data interceptor...' + JSON.stringify(error));
          if (this.isConnectionError(error) &&
            this.isRetryableMethod(request.method) &&
            this.isAPIRequest(request.url)) {
            console.log('Has a retryable request...' + JSON.stringify(request));
            // Store the failed request for later retry
            if (this.implementsEntity(request.body)) {
              this.dirtyDataWriterService.storeFailedRequest(
                new FailedRequestEntity(request.url, request.method, request.body)
              ).then(() => console.log('Request stored for retry'));
              // return a successful response using the body of the request
              return of(new HttpResponse({status: 200, body: request.body}));

            } else if (request.body instanceof FormData) {
              let form : FormData = request.body;
              PhotoService.fromFormData(form).then((entity) => {
                this.dirtyDataWriterService.storeFailedRequest(
                  new FailedRequestEntity(request.url, request.method, entity)
                ).then(() => {
                  console.log('Photo Request stored for retry')
                });
              });
              return of(new HttpResponse({status: 200, body: request.body}));
            }
          }

          return throwError(() => error);
        }
      )
    );

  }

  private implementsEntity(obj: any): obj is Entity<any> {
    console.log(JSON.stringify(obj));
    return 'id' in obj && 'type' in obj;
  }

  private isAPIRequest(url: string): boolean {
    return url.includes('/api/');
  }

  private isConnectionError(error: HttpErrorResponse): boolean {
    return !navigator.onLine || error.status === 0; // Check if offline or status is 0 (connection error)
  }

  private isRetryableMethod(method: string): boolean {
    return ['POST', 'PUT', 'DELETE'].includes(method);
  }


}

export class FailedRequestEntity implements Entity<string> {
  id : string;
  time: number = new Date().getTime();
  type : string;
  url : string;
  body : Entity<any> | FormData | null;
  method : string;
  retries : number = 0;

  constructor(url : string, method : string, body : Entity<any> | FormData | null) {
    this.type = 'FailedRequestEntity';
    this.url = url;
    this.body = body;
    this.method = method;
    if (body) {
      this.id = this.hash(url + method + JSON.stringify(body));
    } else {
      this.id = this.hash(url + method);
    }
  }
  private hash(str : string) : string {
    var hash = 0,
      i, chr;
    if (str.length === 0) return hash + '';
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash + '';
  }

  incrementRetries() {
    this.retries = this.retries+ 1;
  }

}
