<ion-toast
        [isOpen]="isToastOpen"
        [message]="message"
        [duration]="10000"
        (didDismiss)="dismiss()"
        position="top"
        layout="stacked"
        color="danger"
        [buttons]="toastButtons"
></ion-toast>
