import {Injectable, NgZone} from '@angular/core';
import {AppConfigService} from "../../config/app-config.service";
import {NullValidationHandler, OAuthService} from "angular-oauth2-oidc";
import {App, URLOpenListenerEvent} from "@capacitor/app";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {Browser} from "@capacitor/browser";
import {SubscriptionService} from "../subscription/subscription.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService  {


  public hasValidAccessToken : boolean = false;
  public realmRoles : string[] = [];
  public userProfile: any;

  constructor(private appConfig : AppConfigService,
              private oauthService : OAuthService,
              private zone: NgZone,
              private activatedRoute : ActivatedRoute,
              private router: Router,
              private subscriptionService: SubscriptionService
  ) {}

  public async init(): Promise<void> {
    this.configure();

    /**
     * The library offers a bunch of events.
     */
    this.oauthService.events.subscribe(eventResult => {
      if (this.isAccessTokenEvent(eventResult)) {
        console.debug("LibEvent: ", JSON.stringify(eventResult));
      }
      this.hasValidAccessToken = this.oauthService.hasValidAccessToken();
    });

    /**
     * Load discovery document when the app inits
     */
    try {
      await this.oauthService.loadDiscoveryDocument();

      /**
       * Do we have a valid access token? -> User does not need to log in
       */
      this.hasValidAccessToken = this.oauthService.hasValidAccessToken();
      /**
       * Always call tryLogin after the app and discovery document loaded, because we could come back from Keycloak login page.
       * The library needs this as a trigger to parse the query parameters we got from Keycloak.
       */
      this.oauthService.tryLogin().then(() => {
        if (this.hasValidAccessToken) {
          this.loadUserProfile();
          this.realmRoles = this.getRealmRoles();
          this.subscriptionService.userLoggedIn(this.getUsername());
        }
      });
    } catch (error) {
      console.error("loadDiscoveryDocument", error);
    }


  }

  public isAccessTokenEvent(eventResult: any): boolean {
    return eventResult.type === 'token_received' || eventResult.type === 'token_refreshed' || eventResult.type === 'token_expires';
  }
  public isLoggedIn(): boolean {
    return this.hasValidAccessToken;
  }

  public getAuthToken(): string {
    return this.oauthService.getAccessToken();
  }

  public getUsername() : string {
    let idClaims = this.oauthService.getIdentityClaims();
    if (idClaims) {
      return idClaims['preferred_username'];
    } else if (this.userProfile) {
      return this.userProfile.preferred_username;
    }
    throw new Error('No username found in token or user profile');
  }

  public login(): void {
    this.oauthService.loadDiscoveryDocumentAndLogin()
      .catch(error => {
        console.error("loadDiscoveryDocumentAndLogin", error);
      });
  }

  /**
   * Calls the library revokeTokenAndLogout() method.
   */
  public logout(): void {
    this.oauthService.revokeTokenAndLogout()
      .then(revokeTokenAndLogoutResult => {
        this.userProfile = null;
        this.realmRoles = [];
      })
      .catch(error => {
        console.error("revokeTokenAndLogout", error);
      });
  }

  /**
   *  Use this method only when an id token is available.
   *  This requires a specific mapper setup in Keycloak. (See README file)
   *
   *  Parses realm roles from identity claims.
   */
  public getRealmRoles(): string[] {
    let idClaims = this.oauthService.getIdentityClaims()
    if (!idClaims){
      console.error("Couldn't get identity claims, make sure the user is signed in.")
      return [];
    }
    if (!idClaims.hasOwnProperty("realm_roles")){
      console.error("Keycloak didn't provide realm_roles in the token. Have you configured the predefined mapper realm roles correct?")
      return [];
    }

    let realmRoles = idClaims["realm_roles"]
    return realmRoles ?? [];
  }

  public getUserProfile(): any {
    return this.userProfile;
  }

  public loadUserProfile(): void {
    this.oauthService.loadUserProfile()
      .then(loadUserProfileResult => {
        this.userProfile = loadUserProfileResult;
      })
      .catch(error => {
        console.error("loadUserProfile", error);
      });
  }


  /**
   * Configures the app for web deployment
   * @private
   */
  private configure(): void {
    this.appConfig.authConfig.openUri = (url: string) => {
      Browser.open( {
        url: url,
        windowName: '_self'
      })
    }

    this.oauthService.configure(this.appConfig.authConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.tokenValidationHandler = new  NullValidationHandler();

    if (this.appConfig.isIOS || this.appConfig.isAndroid) {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        let url = new URL(event.url);
        if ((this.appConfig.isIOS && url.host != "login") ||
            (this.appConfig.isAndroid && url.pathname != '//login')) {
          // Only interested in redirects to heclogin://login
          return;
        }

        this.zone.run(() => {
          if (this.appConfig.isIOS) {
            Browser.close();
          }
          // Building a query param object for Angular Router
          const queryParams: Params = {};
          for (const [key, value] of url.searchParams.entries()) {
            queryParams[key] = value;
          }

          // Add query params to current route
          this.router.navigate(
            ['tabs','home'],
            {
              relativeTo: this.activatedRoute,
              queryParams: queryParams,
              queryParamsHandling: 'merge', // remove to replace all query params by provided
            })
            .then(() => {
              // After updating the route, trigger login in oauthlib and
              this.oauthService.tryLogin().then(tryLoginResult => {
                if (this.hasValidAccessToken) {
                  this.loadUserProfile();
                  this.realmRoles = this.getRealmRoles();
                  this.subscriptionService.userLoggedIn(this.getUsername()).then();
                }
              })
            })
            .catch(error => console.error(error));

        });
      });
    }
  }

  openAccountSettings() {
    Browser.open( {
      url: this.appConfig.authConfig.issuer + '/account?referrer=' + this.appConfig.authConfig.clientId,
      windowName: '_self'
    });
  }
}
