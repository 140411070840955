import {Component, OnDestroy, OnInit} from '@angular/core';
import {filter, map, tap} from 'rxjs/operators';
import {IonicModule} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {ErrorService} from "../api/error/error.service";

@Component({
  selector: 'app-error',
  templateUrl: 'error.component.html',
  standalone: true,
  imports: [
      IonicModule
  ]


})
export class ErrorComponent implements OnInit, OnDestroy {

  isToastOpen = false;
  message : string | null = null;
  public toastButtons = [
    {
      text: 'Dismiss',
      role: 'cancel',
      handler: () => {
        this.dismiss();
      }
    }
  ]

  error$ = this.errorService.error$.pipe(
    filter(error => error != null),
    tap(error => console.log(`Error received in error component: ${error}`)),
    map(error => {
      this.showToast(error);
    })
  );

  errorSub! : Subscription;

  constructor(private errorService : ErrorService) { }

  clearError() : void {
    this.errorService.clearError();
  }

  dismiss() {
    this.isToastOpen = false;
    this.clearError();
  }

  async showToast(message : string | null) {
    this.message = message || 'Unknown error';
    this.isToastOpen = true;
  }
  ngOnInit() : void {
    this.errorSub = this.error$.subscribe();
  }

  ngOnDestroy() : void {
    this.errorSub.unsubscribe();
  }


}
